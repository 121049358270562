import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ResponsiveRoutingComponent } from './components/responsive-routing/responsive-routing.component';

@NgModule({
    imports: [CommonModule, TranslateModule.forChild()],
    exports: [CommonModule, TranslateModule],
    declarations: [ResponsiveRoutingComponent],
})
export class SharedModule {}
